<!--
 * @Author: CL
 * @Date: 2021-08-02 10:31:03
 * @LastEditTime: 2021-08-02 23:39:49
 * @Description: 音乐组件
-->

<template>
  <div class="musics-contain">
    <div class="title-wrap">
      <h3>{{ info.title }}</h3>
      <p>--{{ info.auth }}</p>
    </div>
    <p>{{ info.desc }}</p>
    <img class="img" :src="info.pic" alt="">
    <audio ref="music" :src="info.path" controls>当前浏览器不支持HTML5音频播放器</audio>
  </div>
</template>

<script>
export default {
  data(){
    return{

    }
  },

  methods: {

  },

  props: {
    info: {
      type: Object,
      required: true
    }
  },

  watch: {
    info: {
      handler(val){
        this.$refs.music.play();
      }
    }
  }
}
</script>

<style lang="less" scoped>
.musics-contain{
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  box-shadow: 0px 0px 4px 4px #ececec;
  border-radius: 5px;
  margin-bottom: 20px;
  box-sizing: border-box;

  .title-wrap{
    display: flex;
    align-items: center;

    h3{
      margin-right: 15px;
    }

    p{
      font-size: 14px;
    }
  }

  .img{
    border-radius: 5px;
    width: 100%;
    height: 74%;
    object-fit: cover;
  }

  audio{
    width: 100%;
    height: 35px;
    margin-top: 15px;
  }
}
</style>
