<!--
 * @Author: CL
 * @Date: 2021-07-28 09:19:06
 * @LastEditTime: 2021-08-03 11:23:50
 * @Description: 音乐
-->

<template>
  <div class="music-contain" ref="music" @scroll="handleScroll">
    <div class="music-list">
      <div class="music-comp">
        <Music :info="currentMusic" />
      </div>
      <div class="music-btns">
        <el-select class="select-wrap" @change="musicChange" v-model="value" placeholder="歌曲列表">
          <el-option
            v-for="item in musicList"
            :key="item.id"
            :label="item.title + ' --' + item.auth"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>

    <GoHome />
    <ToTop :isShow="isShow" />
    <NoMore :isShow="hasMore" word="歌曲" />
  </div>
</template>

<script>
import Music from './components/music.vue';
import GoHome from '@/components/GoHome';
import ToTop from '@/components/ToTop';
import NoMore from '@/components/NoMore';
import music1 from '@/assets/music1.mp3';
import music2 from '@/assets/music2.mp3';
import music3 from '@/assets/music3.mp3';
import music4 from '@/assets/music4.mp3';
import music5 from '@/assets/music5.mp3';
import t7 from '@/assets/t7.jpg';
import t9 from '@/assets/t9.jpg';
import t10 from '@/assets/t10.jpg';
import t11 from '@/assets/t11.jpg';
import t12 from '@/assets/t12.jpg';


export default {
  data(){
    return {
      isShow: false,
      hasMore: false,

      musicList: [
        {
          id: 0,
          title: '像鱼',
          auth: '王贰浪',
          desc: '我要记住你的样子，像鱼记住水的拥抱。',
          path: music1,
          pic: t7,
          isPlay: false
        },
        {
          id: 1,
          title: '来迟',
          auth: '戴羽彤',
          desc: '我这一次终究还是来得太迟，错过了你以后所有的故事。',
          path: music2,
          pic: t9,
          isPlay: false
        },
        {
          id: 2,
          title: 'RingRingRing',
          auth: '不是花火呀',
          desc: '拉长耳朵提高警觉，神经细胞全面戒备。',
          path: music3,
          pic: t10,
          isPlay: false
        },
        {
          id: 3,
          title: '有种',
          auth: '孟美岐',
          desc: '对世界对自我失望过，回避过放弃过遗憾过，现在的我不想再闪躲。',
          path: music4,
          pic: t11,
          isPlay: false
        },
        {
          id: 4,
          title: '最初的梦想',
          auth: '金玟岐',
          desc: '最初的梦想紧握在手上，最想要去的地方怎么能在半路就返航。',
          path: music5,
          pic: t12,
          isPlay: false
        }
      ],

      currentMusic: {},   //当前音乐

      value: ''
    }
  },

  components: {
    Music,
    GoHome,
    ToTop,
    NoMore
  },

  created(){
    this.currentMusic = this.musicList[0];
  },

  methods: {
    handleScrollTop(){
      this.$refs.music.scrollTop = 0;
    },

    handleScroll(){
      if(this.$refs.music.scrollTop == 0){
        this.isShow = false;
      }else if(this.$refs.music.scrollTop > 0){
        this.isShow = true;
      }

      if(this.$refs.music.scrollTop + this.$refs.music.clientHeight >= this.$refs.music.scrollHeight - 50){
        this.hasMore = true;
      }
    },

    /**
     * 切换歌曲
     */
    musicChange(index){
      this.currentMusic = this.musicList[index];
    }
  },

  mounted(){
    this.$bus.$on('scrollTop', this.handleScrollTop);
  },

  destroyed() {
    this.$bus.$off('scrollTop', this.handleScrollTop);
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/mixin.less';

.music-contain{
  .self-fill();
  overflow: scroll;
  scroll-behavior: smooth;

  .nomore-contain{
    padding-bottom: 20px;
  }

  .music-list{
    width: 88%;
    height: 90%;
    min-height: 670px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 4px 4px #ececec;
    margin: 0 auto;
    display: flex;
    padding: 25px;
    box-sizing: border-box;
    border-radius: 5px;

    .music-comp{
      width: 76%;
    }

    .music-btns{
      height: 100%;
      border-radius: 5px;
      width: 20%;
      margin-left: 35px;
      box-shadow: 0px 0px 4px 4px #ececec;
      display: flex;
      flex-direction: column;
      align-items: center;

      .select-wrap{
        margin: 20px auto 0;
      }
    }
  }
}
</style>
